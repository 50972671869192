<template>
  <div id="app" v-if="!themeLoading" class="animate__animated animate__fadeIn" :class="{'G60-theme-color': isG60Theme,'default-theme-color': !isG60Theme}">
    <!-- <UserCenterNavbar v-if="showCenterBar"></UserCenterNavbar> -->
    <div style="height: 44px" v-if="showCenterBar && !windowShowCustomBar">

    </div>
    <TanLeftMenu v-if="showTanRightWindow"/>
    <TPSNavbar v-if="showTPSNavbar"></TPSNavbar>
    <G60Navbar v-else-if="showG60Navbar"></G60Navbar>
    <TanNavbar v-else-if="showTanNavbar && !windowShowCustomBar"></TanNavbar>
    <TanCenterNavbar v-else-if="showTanRightWindow"></TanCenterNavbar>
    <PostLineNavbar v-else-if="showPostLineNavbar"></PostLineNavbar>
    <navbar v-else-if="ismOrpc == 'pc' && !showSearchNoTimBar && showNavbar && !showCenterBar && showFullBar && !isLoginPage && !isPicPage && !windowShowCustomBar"></navbar>
    <navbar-no-tim v-else-if="!showFullBar && !showSearchNoTimBar && !isLoginPage  && !isPicPage && !windowShowCustomBar"></navbar-no-tim>
    <navbar-search-no-tim v-else-if="showSearchNoTimBar && !isLoginPage  && !isPicPage && !windowShowCustomBar"></navbar-search-no-tim>
    <router-view v-slot="{ Component }"  :key="key" :class="{'Tan-view': showTanRightWindow}">
      <!-- 1.7 全屏看路演时隐藏顶部 navbar active-item="-" change-bg-by-scroll -->
      <div :style="`${!showFullBar ? 'margin-bottom: 96px;' : 'margin-bottom: 96px;'}`" class="top-container" v-if="showMiddle && !showCenterBar && !isLoginPage && !isPicPage && !showTPSNavbar && !showG60Navbar && !showPostLineNavbar && !showTanNavbar && !windowShowCustomBar"></div>
        <mobile-navbar v-if="(ismOrpc != 'pc') || !showNavbar"></mobile-navbar>
      <transition name="slide-right" mode="in-out" >
        <component :is="Component" />
      </transition>
    </router-view>
    <!-- 弹窗组件 -->
    <popUp></popUp>

    <InstantMessage v-if="(id > 0 || (getPLId() && isPostLine) || (showTPSNavbar && getTPSId()) || (showTanNavbar && getTanId())) && (ismOrpc == 'pc') && initTim" />

    <!-- 留言窗口 -->
    <LeaveMessage v-if="id > 0 && (ismOrpc == 'pc')"></LeaveMessage>
    <div class="wrapDialog" @click="hideWrapDialog(0)" v-if="imDialogVisible && id > 0 && (ismOrpc == 'pc')">
      <InstantDialog />
    </div>

    <div class="wrapDialog" @click="hideWrapDialog(1)" v-if="imRoleDialogVisible && (ismOrpc == 'pc')">
      <RoleTip />
    </div>

    <!-- 引导注册数字科创中心的提示 -->
    <div class="wrapDialog" @click="hideWrapDialog(2)" v-if="labTipVisible && (ismOrpc == 'pc')">
      <labTip />
    </div>

    <!-- 创建身份弹窗 -->
    <div class="wrapDialog" @click="hideWrapDialog(3)" v-if="createWindowVisible && (ismOrpc == 'pc')">
      <CreateTip/>
    </div>
    <!-- 初次创建身份弹窗 -->
    <div class="wrapDialog" @click="hideWrapDialog(4)" v-if="firstWindowVisible && (ismOrpc == 'pc')">
      <CreateFirst />
    </div>
    <!-- 客服按钮 -->
    <manualService v-if="isShowManual && ismOrpc == 'pc' && !isPicPage && !showTanNavbar"></manualService>

    <Footer v-if="ismOrpc == 'pc' && !isLoginPage && !isPicPage && !showTPSNavbar && !showG60Navbar && !showPostLineNavbar && !showTanNavbar && !windowShowCustomBar"></Footer>
    <G60Footer v-else-if="showG60Navbar && !isG60OutLink && !windowShowCustomBar"></G60Footer>
    <TanFooter v-else-if="showTanNavbar && !windowShowCustomBar"></TanFooter>
    <TanLoginPop v-if="showTanNavbar"></TanLoginPop>
    <TanInvitePop v-if="showTanNavbar"></TanInvitePop>
    <TanVipPop v-if="showTanNavbar"></TanVipPop>
    <TanCompanyEditPopShow v-if="showTanNavbar"></TanCompanyEditPopShow>
    <TanPersonalEditPopShow v-if="showTanNavbar"></TanPersonalEditPopShow>
    <TanQuotationPopShow v-if="showTanNavbar"></TanQuotationPopShow>
    <TanPurchasePopShow v-if="showTanNavbar"></TanPurchasePopShow>
    <!-- 记录面包屑 -->
    <BreadCrumb></BreadCrumb>
  </div>
</template>

<script>
import { getScrollWidth,environment,routeHasTim,routeNewBarTim,otherTim,routeNewSearchBar,picPage,talentProjectSystemPage,G60OutLink,k_customBar,TanNotShowNavbar, } from  "@/utils/consts";
import TPSNavbar from "./views/talent-project-system/components/TPSNavbar.vue";
import G60Navbar from "./views/G60/components/Navbar.vue";
import G60Footer from "./views/G60/components/Footer.vue";
import TanNavbar from "./views/TanSite/components/Navbar.vue";
import TanCenterNavbar from "./views/TanSite/components/CenterNavbar.vue";
import TanFooter from "./views/TanSite/components/Footer.vue";
import TanLeftMenu from './views/TanSite/components/LeftMenu.vue'
import PostLineNavbar from "./views/post-line/components/Navbar.vue";
import Navbar from "@/components/Navbar";
import NavbarNoTim from "./components/NavbarNoTim.vue";
import NavbarSearchNoTim from "./components/NavbarSearchNoTim.vue";
// import UserCenterNavbar from "@/components/UserCenterNavbar/UserCenterNavbar"
import Footer from "@/components/Footer";
import popUp from "@/components/popUp/popUp"

import {mapGetters} from 'vuex'
import CreateTip from '@/components/im/createTip';
import CreateFirst from '@/components/im/firstTip';
import InstantMessage from "@/components/im/InstantMessage";
import LeaveMessage from "./components/im/LeaveMessage.vue";
import InstantDialog from "@/components/im/imTip";
import RoleTip from "@/components/im/imRoleTip";
import { isMobile, getRem } from '@/config/mobile'
import MobileNavbar from "@/views/mobile/navbar";
import manualService from '@/components/manualService';
import BreadCrumb from "@/components/BreadCrumb"  // 此组件仅记录面包屑的路由
import labTip from "@/components/im/labTip";
// import { checkTheme } from "@/utils/tool";
import { openFirstTip } from "./utils/tool";
import { TPSChangeVisibility } from "@/utils/talent-project-system/router";
import { G60ChangeVisibility } from "@/utils/G60/router";
import { TanChangeVisibility } from "@/utils/Tan/router";
import { AIChangeVisibility } from "@/utils/AI/router";
import { PLChangeVisibility } from "@/utils/post-line/router";
import { getPLId } from "@/utils/post-line/login";
import { getTPSId } from "@/utils/talent-project-system/login";
import { G60site,TanSite,PostLineSite,AISite } from "@/router/index";
import TanLoginPop from "./views/TanSite/components/LoginPop.vue";
import TanInvitePop from "./views/TanSite/components/InvitePop.vue";
import TanVipPop from "./views/TanSite/components/NewVipPop.vue";
import TanCompanyEditPopShow from "./views/TanSite/components/CompanyEditPop.vue";
import TanPersonalEditPopShow from "./views/TanSite/components/PersonalEditPop.vue";
import TanQuotationPopShow from "./views/TanSite/components/QuotationPop";
import TanPurchasePopShow from "./views/TanSite/components/PurchasePop";
import { getCookie,setCookie } from "@/utils/Cookies/index";
import { memberDetails } from "@/api/user";
import { getTanId } from "@/utils/Tan/login";

export default {
  name: "app",
  components: { RoleTip, Footer, Navbar, InstantMessage,LeaveMessage, InstantDialog, MobileNavbar,manualService,BreadCrumb,popUp,labTip,CreateTip,CreateFirst,NavbarNoTim,NavbarSearchNoTim,TPSNavbar,G60Navbar,PostLineNavbar,G60Footer,TanNavbar,TanFooter,TanLoginPop,TanInvitePop,TanLeftMenu,TanCenterNavbar,TanVipPop,TanCompanyEditPopShow,TanPersonalEditPopShow,TanQuotationPopShow,TanPurchasePopShow, },
  watch: {
    screenWidth(val){
      if(val < 1300){ // 小屏
        this.$store.dispatch('other/setSmallScreen',true)
      }
      else{
        this.$store.dispatch('other/setSmallScreen',false)
      }
    },
    $route(to) {
      if (to.path == '/live'|| to.path == '/live-room') {
        this.$store.commit('message/SET_SERVICE_WINDOW',false)
      } else {
        this.$store.commit('message/SET_SERVICE_WINDOW',true)
      }
      if(to.query.sDW === '1'){
        this.judgeOpenPop()
      }
      this.changeRoute()
    }
  },
  beforeUnmount(){  // 移除事件监听
    window.removeEventListener('beforeunload',this.beforeunloadHandler)
    document.addEventListener('visibilitychange', this.windowReturn)
    window.removeEventListener("resize",this.setWidth)
  },
  async created(){
    if(TanSite){
      const link = document.querySelector('link[rel="icon"]');
      link.href = 'https://front-file-1304545655.cos.ap-shanghai.myqcloud.com/2024/02/04/1709535736903870/ico.ico'
    }
    const flag = this.checkOtherPage()
    if(!flag) return
    this.changeRoute()
    // this.themeLoading = true
    // let result = await checkTheme() // 获取滤镜色
    // this.$store.dispatch('user/setDarkTheme',result[0])
    // this.$store.dispatch('user/setNewYearTheme',result[1])
    // this.themeLoading = false
  },
  mounted() {
    this.setWidth()
    window.addEventListener("resize",this.setWidth)
    window.addEventListener('beforeunload',this.beforeunloadHandler)
    document.addEventListener('visibilitychange', this.windowReturn);
    if(!AISite) document.body.style.width = `calc(100vw - ${getScrollWidth()}px)`
    else{
      document.body.style.overflowY = 'hidden'
    }
    /* eslint-disable-next-line */
    // let address = returnCitySN
    this.$store.commit('user/SET_LOCATION','全国')
    if (this.isMobile()) {
      this.ismOrpc = 'mobile'
      if(window.location.href.includes('keqidao.com/incubator-vip')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/incubator-vip' : 'https://mobile.keqidao.com/incubator-vip'
        return
      }
      if(window.location.href.includes('keqidao.com/i?id=')){
        const start = window.location.href.indexOf('id=')
        const id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        window.location.href = environment === 'uat' ? `https://umobile.keqidao.com/i?id=${id}` : `https://mobile.keqidao.com/i?id=${id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/tan-invite')){
        const start = window.location.href.indexOf('link=')
        const id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        window.location.href = environment === 'uat' ? `https://umobile.keqidao.com/tan-invite?link=${id}` : `https://mobile.keqidao.com/tan-invite?link=${id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/share')){
        const start = window.location.href.indexOf('id=')
        const id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        window.location.href = environment === 'uat' ? `https://umobile.keqidao.com/AI-share?id=${id}` : `https://mobile.keqidao.com/AI-share?id=${id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/incubator-new')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/incubator-new' : 'https://mobile.keqidao.com/incubator-new'
        return
      }
      else if(window.location.href.includes('keqidao.com/G60-assembly')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/G60-assembly' : 'https://mobile.keqidao.com/G60-assembly'
        return
      }
      else if(window.location.href.includes('keqidao.com/regional-partner')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/regional-partner' : 'https://mobile.keqidao.com/regional-partner'
        return
      }
      else if(window.location.href.includes('keqidao.com/live')){
        const start = window.location.href.indexOf('roomId=')
        const id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        window.location.href = environment === 'uat' ? `https://umobile.keqidao.com/roadshowDetail?id=${id}` : `https://mobile.keqidao.com/roadshowDetail?id=${id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/live-room')){
        const start = window.location.href.indexOf('roomId=')
        const id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        window.location.href = environment === 'uat' ? `https://umobile.keqidao.com/roadshowDetail?id=${id}` : `https://mobile.keqidao.com/roadshowDetail?id=${id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/incubator')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/incubator' : 'https://mobile.keqidao.com/incubator'
        return
      }
      else if(window.location.href.includes('keqidao.com/announce')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/tanjiao' : 'https://mobile.keqidao.com/tanjiao'
        return
      }
      else if(window.location.href.includes('tanjiao.keqidao.com')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/tanjiao' : 'https://mobile.keqidao.com/tanjiao'
        return
      }
      else if(window.location.href.includes('keqidao.com/patent-detail')){
        const start = window.location.href.indexOf('/patent-detail?id=')
        const id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        const prefix = environment === 'uat' ? 'https://uwww.keqidao.com/' : 'https://www.keqidao.com/'
        window.location.href = `${prefix + 'patent-detail?id=' + id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/detail')){
        const isNews = !window.location.href.includes('dynamicId')
        let id = ''
        if(isNews){
          const start = window.location.href.indexOf('/detail?id=')
          id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        }
        else{
          const start = window.location.href.indexOf('/detail?dynamicId=')
          id = window.location.href.slice(window.location.href.indexOf('=',start) + 1)
        }
        const prefix = environment === 'uat' ? 'https://umobile.keqidao.com/' : 'https://mobile.keqidao.com/'
        window.location.href = isNews ? `${prefix + 'shared-news-details?newsId=' + id}` : `${prefix + 'shared-news-details?id=' + id}`
        return
      }
      else if(window.location.href.includes('keqidao.com/SEI-register')){
        // SEI注册
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/SEI-register' : 'https://mobile.keqidao.com/SEI-register'
        return
      }
      else if(window.location.href.includes('keqidao.com/out-demand') && this.isG60Theme){
        // G60外部需求
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/G60-out-demand' : 'https://mobile.keqidao.com/G60-out-demand'
        return
      }
      else if(window.location.href.includes('keqidao.com/out-project') && this.isG60Theme){
        // G60外部成果
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/G60-out-project' : 'https://mobile.keqidao.com/G60-out-project'
        return
      }
      else if(window.location.href.includes('keqidao.com/out-expert') && this.isG60Theme){
        // G60外部专家
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/G60-out-expert' : 'https://mobile.keqidao.com/G60-out-expert'
        return
      }
      else if(window.location.href.includes('keqidao.com/announce')){
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com/tanjiao' : 'https://mobile.keqidao.com/tanjiao'
        return
      }
      setTimeout(() => {
        window.location.href = environment === 'uat' ? 'https://umobile.keqidao.com' : 'https://mobile.keqidao.com'
        return
      }, 750);
      window.onload = () => {
        this.getRem(750,75)
      };
      window.onresize = () => {
        this.getRem(750,75)
      };
      return;
    }
    document.dispatchEvent(new Event('render-event'))
    // 禁用网页的缩放
    const keyCodeMap = {
        // 91: true, // command
        61: true,
        107: true, // 数字键盘 +
        109: true, // 数字键盘 -
        173: true, // 火狐 - 号
        187: true, // +
        189: true, // -
    };
    // 覆盖ctrl||command + ‘+’/‘-’
    document.onkeydown = function (event) {
        const e = event || window.event;
        const ctrlKey = e.ctrlKey || e.metaKey;
        if (ctrlKey && keyCodeMap[e.keyCode]) {
            e.preventDefault();
        } else if (e.detail) { // Firefox
            event.returnValue = false;
        }
    };
    // 覆盖鼠标滑动
    document.body.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
          if (e.deltaY < 0) {
              e.preventDefault();
              return false;
          }
          if (e.deltaY > 0) {
              e.preventDefault();
              return false;
          }
      }
    }, { passive: false });
  },
  data() {
    return {
      isG60Theme: false,
      screenWidth: 0,
      themeLoading: false,  // 加载主题
      ismOrpc: 'pc',
      transitionName: 'slide-right',
      windowShowCustomBar: false,
      isShowManual: true
    }
  },
  computed: {
    ...mapGetters(['id', 'imWindowVisible', 'imDialogVisible', 'imRoleDialogVisible','showNavbar',"showMiddle","labTipVisible","createWindowVisible","firstWindowVisible"]),
    showSearchNoTimBar(){
      // 是否展示搜索无聊天顶部
      return routeNewSearchBar.includes(this.$route.path)
    },
    showPostLineNavbar(){
      return PostLineSite
    },
    showG60Navbar(){
      return G60site || window.location.href.includes('/announce')
    },
    showTanNavbar(){
      return TanSite
    },
    showTanRightWindow(){
      return TanSite && this.$route.path !== '/' && !TanNotShowNavbar.includes(this.$route.path)
    },
    isPostLine(){
      return PostLineSite
    },
    isG60OutLink(){
      return G60OutLink.includes(this.$route.path)
    },
    showTPSNavbar(){
      return talentProjectSystemPage.includes(this.$route.path)
    },
    showCenterBar(){
      // 是否展示用户中心的导航栏
      return this.$route.path === '/user-center' ? true : false
    },
    showFullBar(){
      // 是否展示完整导航栏
      return routeHasTim.includes(this.$route.path)
    },
    isPicPage(){
      return picPage.includes(this.$route.path) || AISite
    },
    initTim(){
      // 需要tim的路由
      return routeHasTim.includes(this.$route.path) || routeNewBarTim.includes(this.$route.path) || otherTim.includes(this.$route.path) || this.showTPSNavbar || this.showG60Navbar || this.showPostLineNavbar || this.showTanNavbar || this.windowShowCustomBar
    },
    isLoginPage(){  // 是否登录页
      const showNew = false
      return this.$route.path === '/login' && showNew
    },
    key(){
      return this.$route.path;
    }
  },
  methods: {
    getTanId,
    changeRoute(){
      if(k_customBar.find((item)=>window.location.href.includes(item)) && k_customBar.find((item)=>this.$route.path === item)){
        if(TanSite) this.windowShowCustomBar = true
      }
      else{
        this.windowShowCustomBar = false
      }
    },
    checkOtherPage(){
      const isTPS = talentProjectSystemPage.find((path)=>{
        return window.location.href.includes(path)
      })
      this.isG60Theme = G60site
      if(this.isG60Theme){
        document.body.classList.add('G60-theme-color');
      }
      const isPostLine = PostLineSite
      if(isTPS || G60site || isPostLine) {
        this.themeLoading = true
        setTimeout(()=>{
          this.themeLoading = false
        },500)
        return false
      }
      else if(TanSite){
        this.themeLoading = true
        TanChangeVisibility(this.$route,false,this.$router)
        setTimeout(()=>{
          this.themeLoading = false
        },1000)
        return false
      }
      else if(AISite){
        this.themeLoading = true
        AIChangeVisibility(this.$route,false,this.$router)
        setTimeout(()=>{
          this.themeLoading = false
        },1000)
        return false
      }
      else if(this.isPostLine){
        this.themeLoading = true
        PLChangeVisibility(this.$route,false,this.$router)
        setTimeout(()=>{
          this.themeLoading = false
        },1000)
        return false
      }
      this.judgeCookie()  // 判断cookie
      return true
    },
    setWidth(){
      this.screenWidth = document.documentElement.clientWidth
    },
    judgeOpenPop(){
      // 判断是否打开弹窗
      if(localStorage.getItem('userInfos')){
        // 已经登录
        openFirstTip()
      }
    },
    getTPSId,
    getPLId,
    isMobile,
    getRem,
    windowReturn(){
      // 设置页面切换
      if(document.hidden){
        // 如果离开页面则隐藏聊天
        this.$store.dispatch("message/hideIMWindow");
      }
      if(talentProjectSystemPage.includes(this.$route.path)) TPSChangeVisibility(this.$route,document.hidden,this.$router)
      else if(G60site) G60ChangeVisibility(this.$route,document.hidden,this.$router)
      else if(TanSite) TanChangeVisibility(this.$route,document.hidden,this.$router)
      else if(AISite) AIChangeVisibility(this.$route,document.hidden,this.$router)
      else if(this.isPostLine) PLChangeVisibility(this.$route,document.hidden,this.$router)
      this.$store.commit('user/SET_DOCUMENT_VISIBILITY', document.hidden ? false : true);
      if(!document.hidden){
        this.judgeCookie()
      }
    },
    judgeCookie(){
      if(!getCookie('KqdInfo') && localStorage.getItem('userInfos')){
        // 本地登陆了却没有cookie
        const info = JSON.parse(localStorage.getItem('userInfos'))
        const localNew = {
          status: '1',
          accessToken: info.accessToken,
          memberInfo: {
            id: info.memberInfo.id,
          },
          refreshToken: info.refreshToken
        }
        setCookie('KqdInfo',localNew)
      }
      else if(getCookie('KqdInfo') && !localStorage.getItem('userInfos')){
        // 有cookie但无本地缓存
        let cookie = JSON.parse(getCookie('KqdInfo'))
        if(cookie.status + '' === '0'){
          // 退出登录状态 退出登录清除缓存
          let form = null
          let obj = null
          if(localStorage.getItem('IncubatorForm')){
            form = JSON.parse(localStorage.getItem('IncubatorForm'))
            obj = JSON.parse(JSON.stringify(form))
          }
          localStorage.clear()
          if(obj){
            localStorage.setItem('IncubatorForm',JSON.stringify(obj))
          }
          this.$store.commit('user/SET_RELOAD_USER')  // 重新获取用户状态
        }
        else {
          localStorage.setItem('userInfos',JSON.stringify(cookie)) // 存入 token
          memberDetails(cookie.memberInfo.id).then(res=>{
            if(res.code === "Sx200"){
              this.$store.dispatch('user/cookieLogin',{
                // 存入信息
                data:{
                  accessToken: cookie.accessToken,
                  refreshToken: cookie.refreshToken,
                  memberInfo: res.data,
                }
              })
            }
          })
        }
      }
      else if(getCookie('KqdInfo') && localStorage.getItem('userInfos')){
        let cookie = JSON.parse(getCookie('KqdInfo'))
        if(cookie.status + '' === '0'){
          // 退出登录状态 退出登录清除缓存
          let form = null
          let obj = null
          if(localStorage.getItem('IncubatorForm')){
            form = JSON.parse(localStorage.getItem('IncubatorForm'))
            obj = JSON.parse(JSON.stringify(form))
          }
          localStorage.clear()
          if(obj){
            localStorage.setItem('IncubatorForm',JSON.stringify(obj))
          }
          this.$store.commit('user/SET_RELOAD_USER')  // 重新获取用户状态
        }
        else if(localStorage.getItem('userInfos') && cookie.memberInfo.id + '' !== JSON.parse(localStorage.getItem('userInfos')).memberInfo.id + ''){
          // 判断是否更换了账号以cookie账号为准
          localStorage.setItem('userInfos',JSON.stringify(cookie)) // 存入新账号的token
          memberDetails(cookie.memberInfo.id).then(res=>{
            if(res.code === "Sx200"){
              this.$store.dispatch('user/cookieLogin',{
                // 存入信息
                data:{
                  accessToken: cookie.accessToken,
                  refreshToken: cookie.refreshToken,
                  memberInfo: res.data,
                }
              })
              this.$store.commit('user/SET_RELOAD_USER')  // 重新获取用户状态
              location.reload() // 刷新页面
            }
          })
        }
      }
    },
    hideWrapDialog(type){ // 关闭遮罩弹窗
      switch (type) {
        case 0:
          this.$store.dispatch("message/hideDialog")
          break;
        case 1:
          this.$store.dispatch("message/hideRoleDialog")
          break;
        case 2:
          this.$store.commit("message/SET_LAB_TIP_VISIBLE",false)
          break;
        case 3:
          this.$store.commit("message/SET_CREATE_WINDOW_VISIBLE",false)
          break;
        case 4:
          this.$store.commit("message/SET_FIRST_WINDOW_VISIBLE",false)
          break;
        default:
          break;
      }
    },
    beforeunloadHandler() {
      // e = e || window.event
      // if (e) {
      //   e.returnValue = '关闭提示'
      // }
      // return '关闭提示'
      //清除cookie
      document.cookie.split(';')
      .forEach(cookie => document.cookie =
      cookie.replace(/^ +/, '')
      .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`));
      console.log('document.cookie:',document.cookie)
    }
  }
}
</script>

<style lang="scss">


html {font-size: 62.5%!important; /* 10÷16=62.5% */}
@media only screen and (min-width: 481px){
    html {
        font-size: 94%!important; /* 15.04÷16=94% */
    }
}
@media only screen and (min-width: 561px){
    html {
        font-size: 109%!important; /* 17.44÷16=109% */
    }
}
@media only screen and (min-width: 641px){
    html {
        font-size: 125%!important; /* 20÷16=125% */
    }
}
* {
  margin: 0;
  padding: 0;
}
html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  // min-width: 938px;
  // padding-right: 0 !important;
}
html{
  min-width: $--min-width;
}
body{
  // overflow-y: auto;
  height: auto !important;
  min-width: $--min-width;
  .el-overlay{
    overflow-x: hidden;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  .el-overlay::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  .custom-window{ // 有标题的弹窗
    .el-dialog__header{
      border-bottom: #D8D8D8 1px solid !important;
      padding: 16px 24px !important;
      .el-dialog__title{
          font-size: 16px !important;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600 !important;
          color: #333333 !important;
          line-height: 24px !important;
      }
    }
    .el-dialog__body{
      padding: 24px 16px 16px 24px !important;
    }
  }
  .el-popDropDownPanelArea{
    // 联级行选
    .el-cascader-panel .el-scrollbar:nth-child(1) .el-radio {
      display: none;
    }
    .el-cascader-panel .el-scrollbar:nth-child(2) .el-radio {
      position: absolute;
      width: 180px;
      left: 0;
      height: 34px;
      opacity: 0;
    }
    .el-cascader-panel .el-scrollbar:nth-child(3) .el-radio {
      position: absolute;
      width: 180px;
      left: 0;
      height: 34px;
      opacity: 0;
    }
  }
  .el-popDropDownPanel{
    // 联级行选
    .el-cascader-panel .el-cascader-menu .el-radio {
      position: absolute;
      width: 180px;
      left: 0;
      height: 34px;
      opacity: 0;
    }
  }
  .el-popDropDownPanelAddress{
    // 地址
    .el-cascader-panel .el-cascader-menu .el-radio {
      position: absolute;
      width: 180px;
      left: 0;
      height: 34px;
      opacity: 0;
    }
  }
  .popper-class-warning{
    background: #FFF9F9 !important;
    border-radius: 4px !important;
    border: 1px solid #F1554E !important;
    padding: 5px 10px !important;
    .el-popper__arrow::before{
      background: #FFF9F9 !important;
      border-right: 1px solid #F1554E !important;
      border-bottom: 1px solid #F1554E !important;
    }
  }
  .popper-class-share-live{
    border-radius: 10px !important;
    border: 1px solid #E8E8E8 !important;
  }
  .popper-class-business{
    z-index: 990 !important;
    p{
      cursor: pointer;
      line-height: 30px;
      width: 100%;
      text-align: center;
      &:hover{
        background-color: #2282ff10;
      }
    }
  }
  .popper-class-tousu{
    .tousu {
      width: 100%;
      border-radius: 10px;
    }
    .tousu-title {
      color: #999;
      text-align: center;
      display: block;
      font-size: 16px;
      padding-bottom: 5px;
    }
    .tousu-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .tousu-item {
      color: #999;
      line-height: 30px;
      font-size: 14px;
      border-radius: 6px;
      width: 100%;
      text-align: center;
      display: block;
      cursor: pointer;
    }
    .tousu-item:hover {
      color: #3C4562;
      background: white;
    }
  }
  .popper-class-user{
    padding: 0 !important;
    .el-popper__arrow::before{
      width: 11px !important;
      height: 11px !important;
      border: none !important;
      // background: $--color-theme !important;
    }
  }
  .popper-class-transparent{
    background: transparent !important;
    box-shadow: none !important;
    border: none !important;
  }
  .popper-class-publish{
    min-width: 96px !important;
    width: 96px !important;
    padding: 5px 0 !important;
    border: none !important;;
    .el-popper__arrow::before{
      width: 11px !important;
      height: 11px !important;
      border: none !important;
    }
  }
  .popper-class{
    .el-cascader-panel .el-scrollbar:first-child{
      .el-checkbox{
        display: none !important;
      }
    }
  }
  // 下拉选项
  .el-select-dropdown__item{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
  }
  .el-select-dropdown__item:hover{
    background-color: #2282ff14;
  }
  // 联级多选
  .el-cascader-menu__list{
    li{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #111111;
    }
    .el-icon-arrow-right{
      display: none;
    }
  }
  // 输入框
  .el-input__inner{
    // font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
  }
  .el-input__inner::placeholder{
    font-weight: 400 !important;
  }
  // 选择输入框
  .el-select__input{
    font-size: 16px ;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
  }
  .el-select__tags{
    .el-select__tags-text{
      font-size: 16px ;
    }
  }
  .el-select-dropdown__item{
    background-color: #ffffff !important;
  }
  .el-select-dropdown__item:hover{
    background-color: #2282ff14 !important;
  }
  .dialog-transparent{
    .el-dialog__body{
      background-color: #75797f !important;
    }
  }
  .link-transparent{
    border-radius: 7px;
    .el-dialog__body{
      background-color: transparent !important;
    }
  }
  .dialog-roadshow-pay{
    .el-dialog__body{
      border-radius: 0 !important;
      border: 2px solid #F8B43B !important;
    }
  }
  .dialog-no-title{
    // 没有title栏的el-dialog
    .el-dialog__header{
      display: none !important;
    }
    .el-dialog__body{
      padding: 0 !important;
    }
  }
  .dialog-b-r-10{
    border-radius: 10px !important;
  }
}

#app {
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #FFFFFF;
}

.gray-bg {
  background: rgb(243, 243, 243);
}

.el-cascader-menu {
  height: 300px;
}

.wrapDialog {
  position: fixed;
  width: 100%;
  background: rgba(0,0,0,0.5);
  height: 100vh;
  z-index: 2003;
}
.default-theme-color{
  .el-cascader-node:hover{
    background: #FFFCF8 !important;
  }
}
.G60-theme-color{
  .is-focus{
    .el-input__inner{
      border-color: $--G60-theme !important;
    }
  }
  .el-input__inner{
    &:focus{
      border-color: $--G60-theme !important;
    }
  }
  .el-textarea__inner{
    &:focus{
      border-color: $--G60-theme !important;
    }
  }
  .el-cascader-menu__list{
    .el-cascader-node:hover{
      background: #00c3820b !important;
    }
    .is-active{
      color: $--G60-theme !important;
    }
    .in-checked-path{
      .el-cascader-node__label{
        color: $--G60-theme !important;
      }
    }
  }
  .el-popDropDownPanelAddress{
    .in-active-path{
      .el-cascader-node__label{
        color: $--G60-theme !important;
      }
    }
  }
  .el-select-dropdown__list{
    .el-select-dropdown__item{
      &:hover{
        background: #00c3820b !important;
      }
    }
    .selected{
      color: $--G60-theme !important;
    }
  }
  .el-form-item__content{
    .is-active{
      border-color: $--G60-theme !important;
    }
  }
  .el-date-range-picker__content{
    .today{
      span{
        color: $--G60-theme !important;
      }
    }
    .start-date{
      span{
        background-color: $--G60-theme !important;
      }
    }
    .end-date{
      span{
        background-color: $--G60-theme !important;
      }
    }
  }
  .el-checkbox{
    .is-checked{
      .el-checkbox__inner{
        border-color: $--G60-theme !important;
        background-color: $--G60-theme !important;
      }
    }
  }
  .is-checked{
    .el-checkbox__label{
      color: $--G60-theme !important;
    }
  }
}
.Tan-view{
  width: 100%;
  box-sizing: border-box;
  padding-left: 242px;
  padding-top: 70px;
}
.el-message-box-flex-start-class{
  .el-message-box__status{
    top: 15%;
  }
}
</style>
